@import "../assets/theme/variable.scss";

.hdr {
  figure.bell_mob {
    display: none;
  }

  @media (max-width: 600px) {
    figure.bell_mob img {
      width: 30px;
      margin-left: 10px;
    }

    figure.bell_mob {
      display: block;
    }
  }

  .hdr_mn {
    background: $primary;

    .hdr_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      gap: 10px;

      @media (max-width: 600px) {
        gap: 5px;
      }

      .hdr_lft {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: 992px) {
          .loctn_fltr {
            display: none;
          }
        }

        @media (max-width: 767px) {
          justify-content: flex-start;
          width: 30%;
          gap: 10px;
        }

        .burger {
          display: none;
          background-color: transparent;
          background-image: none;
          border: none;
          width: 24px;
          transition: 0.2s ease-in-out;

          @media (max-width: 767px) {
            display: block;
          }

          &:hover {
            transform: rotate(-90deg);

            span {
              animation: dance 1s ease-in-out infinite;
              transition-duration: 0.5s;

              &:first-child {
                animation-delay: 0.1s;
              }

              &:nth-child(2) {
                animation-delay: 0.3s;
              }

              &:nth-child(3) {
                animation-delay: 0.5s;
              }
            }
          }

          .burger-block {
            display: flex !important;
            flex-direction: column;
            border-radius: 0.25rem;
            display: inline-block;
            cursor: pointer;
            width: 1.5rem;
            gap: 5px;

            span {
              background-color: $white;
              border-radius: 0.25rem;
              display: inline-block;
              height: 2px;
              width: 1.5rem;
            }

            @keyframes dance {
              0%,
              100% {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }

              33% {
                -webkit-transform: scaleX(0.55);
                transform: scaleX(0.55);
              }

              66% {
                -webkit-transform: scaleX(1.15);
                transform: scaleX(1.15);
              }
            }
          }
        }

        .loctn_fltr {
          @media (max-width: 767px) {
            display: none;
          }
        }

        .select_div {
          margin: 0 30px;
          background: $white;
          border-radius: 10px !important;
          color: $secondary !important;
          font-size: 14px;
          height: 50px;
          box-shadow: 0px 2px 16px #00000014;

          @media (max-width: 992px) {
            margin: 0px 20px;
          }

          svg {
            color: $secondary;
          }
        }

        h2 {
          color: $white;
          font-size: 30px;
          cursor: pointer;

          @media (max-width: 992px) {
            font-size: 25px;
          }
        }
      }

      .hdr_srch {
        width: 100%;
        position: relative;

        @media (max-width: 600px) {
          display: none;
        }
      }

      .hdr_ryt {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        h4 {
          font-size: 16px;
          font-weight: 300;
          color: $white;
          border-bottom: 1px solid $white;
          margin: 0 40px;
          cursor: pointer;

          &:hover {
            color: $secondary;
            border-bottom: 1px solid $secondary;
          }
        }

        .users_dv {
          button:hover {
            background-color: transparent !important;
          }

          img {
            width: 45px;
            height: 45px;
            object-fit: cover;
            border-radius: 50%;
          }

          @media (max-width: 992px) {
            img {
              width: 40px;
              height: 40px;
            }
          }

          @media (max-width: 600px) {
            img {
              width: 35px;
              height: 35px;
            }
          }

          svg {
            color: white;
          }
        }

        .hdr_btn {
          background: white;
          border-radius: 40px;
          padding: 0 16px 0 8px;
          height: 48px;
          color: $secondary;

          @media (max-width: 600px) {
            min-width: 1px;
            padding: 0px;
            background: transparent;
          }

          figure {
            height: 34px;
            width: 34px;
            margin-right: 6px;

            @media (max-width: 600px) {
              margin-right: 0px;
              border: 1px solid #ffff;
              border-radius: 50%;
            }
          }
        }

        .userdiv {
          display: flex;
          align-items: center;

          figure {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  .btm_hd {
    padding: 16px;
    box-shadow: 0 2px 14px 1px rgba(0, 0, 0, 0.1);
    // display: none;

    @media (max-width: 767px) {
      display: none;
    }

    @media (max-width: 600px) {
      display: block;
    }

    .hdr_btm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      @media (max-width: 1200px) {
        justify-content: flex-start;
        gap: 20px;
      }

      @media (max-width: 767px) {
        display: none;
      }

      li {
        // a {
        //   text-decoration: none;
        h4 {
          font-size: 14px;
          font-weight: 400;
          color: $secondary;
          border-bottom: 1px solid transparent;
          cursor: pointer;

          &:hover {
            border-bottom: 1px solid $secondary;
          }
        }

        h2 {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          color: $secondary;
          cursor: pointer;
        }

        // }
      }
    }
  }
}

.hdr_mnu {
  .itemlogo {
    background-color: $primary;
    padding: 20px 0px;
    position: relative;

    svg {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $white;
    }

    .logo {
      h2 {
        text-align: center;
        color: white;
        font-size: 30px;
        font-weight: 600;
      }
    }
  }

  .loctn_fltr {
    margin: auto;
    width: 90%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px;
    border-radius: 40px !important;

    .select_div > div {
      padding: 12px !important;
    }
  }

  .hdr_ryt {
    margin-bottom: 20px;

    .hdr_btn {
      background: $primary;
      border-radius: 40px;
      padding: 0 16px 0 8px;
      height: 48px;
      color: $white;

      width: 90%;
      display: flex;
      margin: 0 auto;

      figure {
        display: none;
      }
    }
  }

  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
  }
}

button.mob_btn {
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
}

@media (max-width: 600px) {
  button.desk_btn {
    display: none;
  }
}

//footer

.ftr {
  .ftr_top {
    background: $c_black;

    .mn_ftr {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 0;
      gap: 10px;
      flex-wrap: wrap;

      li {
        width: 14%;

        @media (max-width: 992px) {
          width: 28%;
        }

        @media (max-width: 600px) {
          width: 100%;
          margin-top: 20px;
        }

        &:first-child {
          // width: 40%;
          width: 29%;

          @media (max-width: 600px) {
            width: 100%;
          }
        }

        &:last-child {
          width: 25%;

          @media (max-width: 992px) {
            width: 50%;
            margin-top: 30px;
          }

          @media (max-width: 600px) {
            width: 100%;
          }
        }

        h2 {
          color: white;
          font-size: 40px;
          font-weight: 700;
        }

        p {
          color: $white;
          font-size: 15px;
          font-weight: 300;
          line-height: 24px;
          padding-top: 20px;
        }

        h4 {
          font-size: 20px;
          font-weight: 500;
          color: $white;
          margin-bottom: 16px;
        }

        .sub_btn {
          display: flex;
          justify-content: center;

          & > div {
            width: 60% !important;
            margin-top: 0px !important;

            input {
              width: 100%;
            }
          }

          button {
            width: 40%;
            background: $primary;
            border: none;
            border-radius: 0 4px 4px 0;
            font-size: 15px;
            color: $white;
            cursor: pointer;
          }
        }

        h6 {
          margin-top: 16px;
          font-size: 15px;
          color: $white;
          font-weight: 300;
          cursor: pointer;

          &:hover {
            color: $primary;
          }
        }

        .scl_icns {
          padding-top: 16px;
          display: flex;
          justify-content: flex-start;

          figure {
            background: $white;
            height: 35px;
            width: 35px;
            margin-right: 10px;
            border-radius: 50%;
            padding: 8px;
            cursor: pointer;
          }
        }

        .subscribe_ftr {
          padding-top: 30px;

          .ftr_txtfld {
            width: 260px;
            border-radius: 4px;
            margin-top: 16px;
            p {
              position: absolute;
              top: 30px;
              left: -10px;
              color: red;
              width: 200px;
            }
            input {
              background-color: #444444;
              color: $white;
              border-radius: 4px 0 0 4px !important;

              &::placeholder {
                color: white;
              }
            }
          }
        }
      }
    }
  }

  .ftr_btm {
    background: #820000;
    padding: 10px 0;
    color: $white;
    text-align: center;

    p {
      font-size: 15px;
      font-weight: 300;
    }
  }
}

.select_div > div {
  // padding-right:  20px !important;
  border: none !important;
}

.select_div fieldset {
  display: none;
}

.notiDropdown_menu {
  ul {
    display: flex;
    width: 100%;
    gap: 10px 42px;
    /* margin: auto; */
    flex-wrap: wrap;
    padding: 0 20px;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;

    li {
      width: 17%;
      margin-bottom: 20px;

      h3 {
        font-size: 16px;
        color: $secondary;
        &:hover {
          color: $primary;
        }
      }

      .sub_cat {
        margin-top: 20px;

        p {
          font-size: 16px;
          color: #5d5d5d;
          padding-bottom: 10px;
          // cursor: pointer;

          // &:hover {
          //   color: $primary;
          // }
        }
      }
    }
  }
}

.logo-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 16px #00000014;
  padding: 20px;

  h2 {
    text-align: center;
    color: $primary;
    font-size: 30px;
    cursor: pointer;
  }
}

//search div

.product_srch {
  min-height: 90px;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  position: absolute;
  width: 100%;
  z-index: 1;
  max-height: 370px;
  overflow-y: scroll;
  // max-width: 590px;
  margin-top: 10px;
  border-radius: 14px;
  .srch_hdng {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    color: $secondary;
    .clr_btn {
      cursor: pointer;
      border-bottom: 1px solid transparent;
      &:hover {
        border-bottom: 1px solid $secondary;
      }
    }
  }
  .items_div {
    border-bottom: 1px solid #8080806b;
    margin-bottom: 20px;

    p {
      color: grey;
      text-align: left;
    }
  }

  ul {
    li.main_li {
      display: flex;
      align-items: center;
      margin-bottom: 11px;
      cursor: pointer;
      padding: 5px;

      &:hover {
        background: #8080801a;
      }

      .li_left {
        figure {
          width: 50px !important;
          height: 50px !important;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .li_ryt {
        width: 100%;
        margin-left: 10px;

        .stock_flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          h3 {
            font-size: 16px;
            font-family: "bold";
            word-break: break-all;
          }
        }

        p {
          font-size: 13px;
          color: grey;
          font-family: "regular";
        }
      }
    }
  }

  h2 {
    font-size: 16px;
    width: 100%;
    height: 100%;
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 600px) {
    .users_dv.MuiBox-root {
      display: none;
    }
  }
}

.srch_hdr {
  .search_bar {
    display: none;
    @media (max-width: 600px) {
      display: block;
    }
    input {
      @media (max-width: 600px) {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
      }
    }
  }
  .product_srch {
    display: none;
    @media (max-width: 600px) {
      display: block;
      left: 0 !important;
    }
  }
}

.categoty_fltrs.new_catgry {
  overflow: scroll;
  height: 85px;
  flex-wrap: nowrap;
  overflow-y: hidden;
}
.location_icnnn {
  height: 30px;
  width: 30px;
  margin: 0 16px 0 20px;
  cursor: pointer;
}

.logo_img {
  height: 40px;
  width: 130px;
}
.logo_img2 {
  height: 60px;
  width: 200px;
}
