@import "../../assets/theme/variable.scss";

.filters {
  h2 {
    font-size: 28px;
    color: $secondary;
  }

  .sub_catMain {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      // position: relative;
    }

    .sc_lft {
      background: #ffffff 0% 0% no-repeat padding-box;
      width: 26%;
      border-radius: 15px;
      border: 1px solid #dddddd;
      margin-top: 10px;

      @media (max-width: 767px) {
        width: 100%;
      }

      .sub {
        .filtr_top_hdng {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #dddddd;
          padding: 15px;
        }
        h4 {
          font-size: 20px;
          padding: 15px 0;
          border-bottom: 1px solid #dddddd;
          padding-left: 15px;
          &:first-child {
            border-bottom: 1px solid transparent;
            padding: 0;
          }
        }
        p {
          cursor: pointer;
          border-bottom: 1px solid transparent;
          &:hover {
            border-bottom: 1px solid black;
          }
        }
        h3 {
          font-size: 20px;
          padding: 15px 0;
          padding-left: 15px;
        }

        ul {
          padding: 15px;
          border-bottom: 1px solid #dddddd;

          @media (max-width: 767px) {
            border-bottom: none;
          }

          li {
            font-size: 18px;
            color: $secondary;
            padding-bottom: 10px;
            cursor: pointer;
          }

          .actv_cat {
            color: $primary;
          }
        }
      }
    }

    .sc_Ryt {
      width: 73%;

      @media (max-width: 767px) {
        width: 100%;
      }

      .prdct_crd {
        width: 24%;
        margin-bottom: 15px;

        @media (max-width: 1200px) {
          width: 32%;
        }

        @media (max-width: 992px) {
          width: 48%;
        }

        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }
  }
}
.hide_prdcts {
  @media (max-width: 767px) {
    display: none;
  }
}

// .pagination {
//   display: flex;
//   justify-content: center;
//   margin-top: 40px;
//   button {
//     &.Mui-selected {
//       background: $primary;
//       color: $white;
//     }
//     &:hover {
//       color: $white;
//       background: $primary;
//     }
//   }
// }

//pagination
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "regular";
    font-size: 18px;
  }

  p {
    font-size: 14px;

    span {
      font-family: "bold";
      color: black;
    }
  }

  .pages {
    display: flex;
    .select_pgntn {
      margin-right: 10px;
      & > div {
        padding: 10.5px 14px !important;
      }
      svg {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    .prevbtn {
      background: no-repeat;
      border: none;
    }

    svg {
      font-size: 26px;
      border: 0.5px solid #a5a5a5;
      padding: 6px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-right: 10px;
      cursor: pointer;
      color: black;
    }

    .count {
      display: flex;
      align-items: center;
      margin-right: 10px;
      background: none;
      /* padding: 6px; */
      border-radius: 24px;
      border: 0.5px solid #fff;

      .actv {
        background: $secondary;

        color: white;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
        border: none;
      }

      .inActv {
        background: transparent;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: black;
        cursor: pointer;
        border: none;
      }
    }
  }
}

.fltr_btn_sc {
  display: flex;
  justify-content: flex-end;

  .fltr_btnn {
    background: $primary;
    color: $white;
    border-radius: 30px;
    padding: 10px 20px;
    margin-top: 10px;
    margin-left: auto;
    border: 1px solid $primary;
    cursor: pointer;
    &:hover {
      background: white;
      color: $primary;
      border: 1px solid $primary;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.filters_dv {
  display: flex;
  justify-content: space-between;
  align-items: center;

  figure {
    height: 25px;
    width: 25px;

    img {
      object-fit: contain;
      cursor: pointer;
    }
  }
}

.filters_dv img {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

@media (max-width: 767px) {
  .sc_lft {
    display: none;
  }

  .sc_lft.mob_dsply {
    display: block;
  }
}
.mob_fltr_btn {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}
