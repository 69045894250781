@import "../assets/theme/variable.scss";

//Product Card
.prdct_crd {
  cursor: pointer;
  padding: 14px;
  width: 19%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;

  @media (max-width: 1200px) {
    width: 32%;
    margin-bottom: 20px;
  }

  @media (max-width: 992px) {
    width: 32%;
  }

  @media (max-width: 767px) {
    width: 48%;
  }

  @media (max-width: 600px) {
    width: 48%;
    padding: 10px;
  }

  &:hover {
    background: #7dbfdd21;
  }

  .prdct_img {
    position: relative;
    width: 100%;
    height: 190px;

    @media (max-width: 600px) {
      height: 145px;
    }

    img {
      object-fit: cover;
      border-radius: 10px;
    }

    .boosterIcon {
      position: absolute;
      top: 8px;
      left: 8px;
      background: white;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: $secondary;
        font-size: 18px;
      }
    }
    .fvrt_img {
      position: absolute;
      top: 8px;
      right: 8px;
      background: white;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: $secondary;
        font-size: 18px;
      }
    }
  }

  .prdct_dtl {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-size: 13px;
      font-weight: 300;
      color: $gray;
      text-transform: uppercase;

      @media (max-width: 767px) {
        font-size: 11px;
      }
      @media (max-width: 600px) {
        font-size: 10px;
      }

      &:last-child {
        color: $secondary;
        font-weight: 500;
      }
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    padding: 4px 0;

    @media (max-width: 767px) {
      font-size: 15px;
    }
  }

  p {
    color: $gray;
    font-size: 13px;
    font-weight: 300;
    //
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

//Product listing card
.prdct_listing_crd {
  position: relative;
  cursor: pointer;
  width: 48%;
  padding: 14px 10px;
  box-shadow: 0px 2px 16px #00000014;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;

  @media (max-width: 767px) {
    width: 100%;
  }

  .right_arrw {
    position: absolute;
    right: 14px;
    top: 38%;
  }

  .lst_crd_lft {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    figure {
      width: 90px;
      height: 80px;
      margin-right: 10px;

      img {
        object-fit: cover;
        border-radius: 6px;
      }
    }

    .lst_dtl {
      h6 {
        color: #5d5d5d;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        margin-top: -6px;

        figure {
          height: 30px;
          width: 30px;

          img {
            object-fit: contain;
          }
        }
      }

      h4 {
        font-size: 16px;
        font-weight: 500;
        color: $c_black;
      }

      p {
        font-size: 12px !important;
        font-weight: 300 !important;
        color: $gray !important;
        padding: 4px 0;
        padding-top: 0 !important;
      }

      h3 {
        color: $secondary;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .lst_crd_ryt {
    display: flex;
    align-items: flex-end;

    figure {
      height: 22px;
      width: 22px;

      img {
        object-fit: contain;
      }
    }
  }
}
