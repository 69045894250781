@import "../../assets/theme/variable.scss";

// products
.pt {
  padding: 50px 0 30px 0 !important;

  @media (max-width: 600px) {
    padding: 20px 0 20px 0 !important;
  }
}

.prodcts_sc {
  padding: 10px 0 0;

  .prdct_hdngs {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 480px) {
      padding: 20px 0;
    }

    h2 {
      font-size: 36px;
      font-weight: 500;
      color: $secondary;

      @media (max-width: 992px) {
        font-size: 30px;
      }

      @media (max-width: 480px) {
        font-size: 26px;
      }

      @media (max-width: 420px) {
        font-size: 20px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      color: $secondary;
      border-bottom: 1px solid $secondary;
      cursor: pointer;
    }

    @media (max-width: 420px) {
      font-size: 14px;
    }
  }

  .crds_sec {
    display: flex;
    gap: 1.2%;
    align-items: normal;
    flex-wrap: wrap;

    @media (max-width: 1200px) {
      gap: 2%;
    }

    @media (max-width: 767px) {
      gap: 4%;
    }
  }
}

//Download App

.dwnld_bnr {
  margin-top: 40px;
  background-color: $primary;

  @media (max-width: 992px) {
    margin-top: 0px;
  }

  @media (max-width: 480px) {
    margin-top: 0px;
  }

  .mn_bnr_dwnld {
    display: flex;
    justify-content: space-between;

    // align-items: center;
    .dwnld_bnr_lft {
      padding: 60px 0 40px;
      width: 50%;

      @media (max-width: 600px) {
        width: 100%;
      }

      @media (max-width: 480px) {
        padding: 40px 0 30px;
      }

      h2 {
        color: $white;
        font-size: 48px;
        font-weight: 700;

        @media (max-width: 992px) {
          font-size: 44px;
        }

        @media (max-width: 767px) {
          font-size: 34px;
        }

        @media (max-width: 600px) {
          text-align: center;
        }

        @media (max-width: 480px) {
          font-size: 30px;
        }
      }

      p {
        color: $white;
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
        padding: 20px 0;

        @media (max-width: 992px) {
          font-size: 16px;
          line-height: 26px;
        }

        @media (max-width: 767px) {
          font-size: 14px;
          padding: 0;
        }

        @media (max-width: 600px) {
          text-align: center;
        }
      }

      .plystr_btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 600px) {
          justify-content: center;
        }

        figure {
          height: 50px;
          width: 180px;
          margin-right: 20px;
          cursor: pointer;

          @media (max-width: 992px) {
            width: 140px;
          }

          @media (max-width: 767px) {
            width: 115px;
          }

          img {
            object-fit: contain;
          }
        }
      }
    }

    .dwnld_bnr_ryt {
      width: 50%;
      position: relative;
      overflow: hidden;

      @media (max-width: 600px) {
        display: none;
      }

      figure {
        position: absolute;
        height: 490px;
        width: 270px;
        left: 160px;

        @media (max-width: 992px) {
          width: 260px;
          left: 100px;
        }

        @media (max-width: 767px) {
          width: 240px;
          left: 40px;
        }
      }
    }
  }
}

//home slider

.hm_bnr {
  .bnr {
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 10px;
    padding: 20px;
    cursor: grab;
  }

  .mn_bnr {
    display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    position: relative;
    // align-items: center;
    // span {
    //   &::after {
    //     content: "";
    //     top: 50%;
    //     left: 0px;
    //     position: absolute;
    //     height: 1px;
    //     width: 80%;
    //     background: #ffffff;
    //     @media (max-width: 767px) {
    //       top: 50%;
    //     }
    //     @media (max-width: 600px) {
    //       display: none;
    //     }
    //   }
    // }

    .bnr_lft {
      width: 70%;

      @media (max-width: 600px) {
        width: 100%;
      }

      h1 {
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        color: $white;
        width: 75%;
        padding-bottom: 20px;

        @media (max-width: 992px) {
          font-size: 36px;
          width: 85%;
          padding-bottom: 0px;
        }

        @media (max-width: 767px) {
          font-size: 30px;
          width: 95%;
          padding-bottom: 14px;
          line-height: 42px;
        }

        @media (max-width: 600px) {
          font-size: 26px;
          width: 95%;
          padding-bottom: 0;
          line-height: 40px;
        }
      }

      p {
        font-size: 20px;
        font-weight: 300;
        color: white;
        padding: 20px 0;

        @media (max-width: 767px) {
          font-size: 18px;
          padding: 12px 0;
        }

        @media (max-width: 600px) {
          font-size: 16px;
        }
      }

      button {
        background: $white;
        color: #1d1d1d;
        padding: 10px 25px;
        font-size: 18px;
        border: none;
        border-radius: 6px;
        cursor: pointer;

        @media (max-width: 767px) {
          font-size: 16px;
          padding: 8px 20px;
        }

        @media (max-width: 600px) {
          display: flex;
        }
      }
    }

    .bnr_ryt {
      width: 30%;
      display: flex;
      justify-content: flex-end;

      @media (max-width: 600px) {
        display: none;
      }

      figure {
        height: 240px;
        width: 270px;
        position: relative;
        z-index: 99;

        @media (max-width: 767px) {
          height: 180px;
        }

        @media (max-width: 767px) {
          height: 170px;
        }

        img {
          object-fit: contain;
        }
      }
    }
  }
}

.slick-dots {
  bottom: -45px !important;

  .slick-active {
    button {
      &::before {
        color: $secondary !important;
        opacity: 1 !important;
        font-size: 14px !important;
        border: 1px solid $secondary !important;
        border-radius: 50%;
        line-height: 22px !important;
      }
    }
  }

  li {
    button::before {
      font-size: 14px !important;
    }
  }
}

//categories

.hm_categories {
  display: none;
}

.hm_categories {
  padding-top: 40px;

  @media (max-width: 767px) {
    display: flex;
  }

  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;

    li {
      width: 17%;

      @media (max-width: 767px) {
        width: 22%;
      }

      @media (max-width: 600px) {
        width: 21%;
      }

      @media (max-width: 480px) {
        width: 19%;
      }

      figure {
        height: 100px;
        width: 100px;
        margin: 0 auto;

        @media (max-width: 600px) {
          height: 80px;
          width: 80px;
        }

        @media (max-width: 480px) {
          height: 60px;
          width: 60px;
        }

        img {
          object-fit: contain;
        }
      }

      h6 {
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 992px) {
    padding-top: 90px;
  }
}
