// privacy
.common_policy {
  padding: 50px 0px;
}

.common_policy p {
  color: #5d5d5d;
  font-size: 18px;
}

.privt_one h1 {
  font-size: 48px;
}

.privt_one p {
  margin-top: 25px;
}

.privt_one {
  text-align: center;
  margin-bottom: 40px;
}

.privicy h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.privcy_dv li h3 {
  font-size: 26px;
}

.privcy_dv li {
  margin-bottom: 20px;
}

.privcy_dv li h3 {
  margin-bottom: 10px;
}

.privcy_dv {
  margin-top: 20px;
}

// privacy end

.about_flex {
  display: flex;
  justify-content: space-between;
}

.about_lft {
  width: 55%;
  background-color: #7dbfdd;
  display: flex;
}

.about_lft_udr {
  max-width: 554px;
  margin: auto;
  padding: 30px;
}

.about_lft h1 {
  font-size: 70px;
  margin-bottom: 10px;
}

.about_lft p {
  font-size: 22px;
}

.about_rgt {
  width: 45%;
}

.about_content {
  text-align: center;
}

.about_content h2 {
  font-size: 48px;
  margin-bottom: 20px;
}

.about_content p {
  margin-bottom: 30px;
}

.about_rgt figure {
  height: 100%;
  width: 100%;
}

.about_td h2 {
  font-size: 40px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.about_td {
  background-color: #1d1d1d;
  padding: 30px;
}

// about us end

.contact_frm {
  margin-top: 30px;
}

.contact_frm h2 {
  font-size: 26px;
  margin-bottom: 20px;
}

.about_td button {
  width: 200px !important;
  margin: 0 auto;
  display: flex;
}

.contact_us h1 {
  font-size: 42px;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 992px) {
    font-size: 32px;
  }

  @media (max-width: 600px) {
    font-size: 30px;
  }
}

.contact_frm button {
  width: 200px !important;
}

.contact_us p {
  //     text-align: center;
}

.contact_frm h2 {
  text-align: left;

  @media (max-width: 992px) {
    font-size: 30px;
  }

  @media (max-width: 600px) {
    font-size: 25px;
  }
}

.conact_flx {
  display: flex;
  justify-content: space-between;
}

.conact_one {
  width: 49%;
}

.conact_two {
  width: 49%;
}

// contact_us end

.faq_dv h1 {
  font-size: 45px;
  text-align: center;
  margin-bottom: 30px;
}

.faq_accoden li {
  margin-bottom: 20px;
}
.admin_dtls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 26px;
  margin-top: 20px;
  p {
    a {
      margin-left: 4px;
    }
  }
}
