@import "../../assets/theme/variable.scss";

.addProduct {
  h2 {
    font-size: 28px;
    text-align: center;
  }
  h5 {
    font-size: 18px;
    text-align: center;
    width: 38%;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 25px;
    font-weight: 400;
    @media (max-width: 767px) {
      width: 60%;
    }
    @media (max-width: 420px) {
      width: 100%;
    }
  }
  ul {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
    gap: 20px;
    li {
      position: relative;
      background: $white;
      width: 23%;
      border-radius: 20px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px dashed $secondary;
      cursor: pointer;
      @media (max-width: 767px) {
        width: 48%;
      }
      @media (max-width: 575px) {
        width: 47%;
      }
      @media (max-width: 420px) {
        width: 100%;
      }
      svg {
        font-size: 50px;
        color: $secondary;
      }
      h3 {
        font-size: 17px;
        font-weight: 400;
        color: $gray;
      }
      figure {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 20px;
      }
      .cross {
        font-size: 26px;
        background: white;
        border-radius: 100%;
        padding: 3px;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: 5px;
        right: 5px;
      }
    }
    label {
      position: relative;
      background: $white;
      width: 23%;
      border-radius: 20px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px dashed $secondary;
      cursor: pointer;
      @media (max-width: 767px) {
        width: 48%;
      }
      @media (max-width: 575px) {
        width: 47%;
      }
      @media (max-width: 420px) {
        width: 100%;
      }
      .uploader {
        text-align: center;
      }
      svg {
        font-size: 50px;
        color: $secondary;
      }
      h3 {
        font-size: 17px;
        font-weight: 400;
        color: $gray;
      }
    }
  }
  .btn {
    width: 150px;
    margin: 0 auto;
  }
}

.select_add {
  background: rgb(235, 235, 235) !important;
  border-radius: 50px !important;
  margin-bottom: 15px !important;
  color: black !important;
  font-weight: 200 !important;
  font-size: 14px !important;
}
.MakeOff {
  display: flex;
  justify-content: flex-end;
  h3 {
    margin-right: 44px;
    font-size: 17px;
    color: rgb(125, 191, 221);
  }
}
.booster {
  margin-bottom: 20px;
  .boosterdiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .rado_label {
    display: flex !important;
    justify-content: space-between !important;
  }
}
.add_prodct_dv {
  background: $primary;
  color: white;
  padding: 14px 20px;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  width: 200px;
}
