@import "../../assets/theme/variable.scss";

.notification_pg {
  p {
    color: #696565;
    font-size: 16px;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  .rcnt_notification {
    background: $secondary;
    padding: 16px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    .ntf_lft {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      figure {
        height: 50px;
        width: 50px;
        margin-right: 10px;
        img {
          object-fit: cover;
          border-radius: 50%;
        }
      }
      h6 {
        color: white;
        font-size: 18px;
        font-weight: 400;
      }
    }
    .ntf_tm {
      padding: 0 !important;
      font-size: 14px;
      color: white;
    }
  }
  .old_notification {
    background-color: white;
    // background-color: white;
    margin-bottom: 0px;
    // padding: 6px 10px;
    h6 {
      color: $c_black;
    }
  }
  .ntf_tm2 {
    color: #1a1824 !important;
    padding: 0 !important;
    font-size: 14px;
  }
}
.clr_ntfctn_btn {
  color: $secondary;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  &:hover {
    color: $primary;
    border-bottom: 1px solid $primary;
  }
}
