@import "/src/assets/theme/variable.scss";

.AuthWrap {
  position: relative;
  overflow: hidden;
  padding-top: 70px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 16px;
  .cross {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;
    svg {
      cursor: pointer;
      color: $c_black;
    }
  }
  .dbl {
    display: flex;
    justify-content: space-between;
    width: 93%;
  }
  &::after {
    content: "";
    position: absolute;
    background: #7dbfdd29;
    width: 400px;
    height: 300px;
    top: -208px;
    right: -168px;
    border-radius: 50%;
  }
  h2 {
    font-size: 28px;
    color: $secondary;
  }
  p {
    font-size: 16px;
    color: #908fa1;
    padding-top: 7px;
    padding-bottom: 20px;
    span {
      color: $secondary;
    }
  }
  .remmerbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    svg {
      width: 22px;
      height: 22px;
    }
    span {
      font-size: 16px;
      color: $gray;
      font-weight: 400;
    }
    .anchor_link {
      color: $primary;
      cursor: pointer;
      h3 {
        font-size: 16px;
        color: $primary;
        font-weight: 400;
        &:hover {
          color: $c_primary_hover;
        }
      }
    }
  }
  .continue {
    h4 {
      font-size: 16px;
      text-align: center;
      margin-top: 15px;
      color: $secondary;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    h5 {
      font-size: 16px;
      text-align: center;
      margin-top: 20px;
      color: $secondary;
      font-weight: 400;
    }
  }
  .Or_div {
    p {
      color: #6e6e6e;
      font-size: 16px;
      position: relative;
      text-align: center;
      padding-bottom: 0;
      &::before {
        background: $lightGrey;
        content: "";
        height: 1px;
        position: absolute;
        top: 18px;
        width: 45%;
        left: 0;
      }
      &::after {
        background: $lightGrey;
        content: "";
        height: 1px;
        position: absolute;
        top: 18px;
        width: 45%;
        right: 0;
      }
    }
  }
  .resend {
    margin: 30px 0 50px 0px;
    h4 {
      font-size: 16px;
      text-align: center;
      margin-top: 15px;
      color: $secondary;
      font-weight: 400;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .social_icn {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    figure {
      width: 36px;
      height: 36px;
      padding: 7px;
      border-radius: 100%;
      margin-right: 15px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      cursor: pointer;
    }
  }
  .signUp {
    h4 {
      font-size: 16px;
      text-align: center;
      font-weight: 400;
      color: $c_black;
    }
    span {
      color: $secondary;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .blacK {
    margin-bottom: 140px;
  }
  .opt_fields {
    // max-width: 300px;
    margin-inline: auto;
    display: flex;
    input {
      background: #ebebeb;
      padding-bottom: 4px !important;
    }
  }
  .image {
    width: 80px;
    height: 80px;

    border-radius: 50%;
    margin: 20px 0;
    padding: 4px;
    outline: 1px solid $secondary !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
    figure {
      width: 100%;
      height: 100%;
      background: $lightGrey;
      overflow: hidden;
      margin: auto;

      border-radius: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    svg {
      position: absolute;
      bottom: -13px;
      background: $secondary;
      border-radius: 50%;
      color: $white;
      cursor: pointer;
      font-size: 21px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
@media (max-width: 420px) {
  .css-1tysami {
    width: 300px !important;
  }
}
