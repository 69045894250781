@import "../../assets/theme/variable.scss";

.vrfctn_dv {
  position: relative;

  .vrfctn_btn2 {
    border: none;
    color: red;
    background: transparent;
    min-width: fit-content !important;
    position: absolute;
    font-weight: 500;
    cursor: pointer;
    top: 0;
    right: 15px;
    bottom: 0;

    &:hover {
      color: red !important;
      background: transparent !important;
    }
  }

  .vrfctn_btn {
    border: none;
    color: $primary;
    background: transparent !important;
    min-width: fit-content !important;
    position: absolute;
    font-weight: 500;
    cursor: pointer;
    top: 0;
    right: 15px;
    bottom: 0;

    &:hover {
      color: $primary !important;
      background: transparent !important;
    }
  }

  .verified {
    height: 25px !important;
    width: 25px !important;
    position: absolute;
    top: 10px;
    right: 15px;
    bottom: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.vrfctn_dv2 {
  position: relative;

  .vrfctn_btn2 {
    border: none;
    color: red;
    background: transparent;
    min-width: fit-content !important;
    position: absolute;
    font-weight: 500;
    cursor: pointer;
    top: -40px;
    right: 15px;
    bottom: 0;

    &:hover {
      color: red !important;
      background: transparent !important;
    }
  }

  .vrfctn_btn {
    border: none;
    color: $primary;
    background: transparent;
    min-width: fit-content !important;
    position: absolute;
    font-weight: 500;
    cursor: pointer;
    top: -10px;
    right: 15px;
    bottom: 0;

    &:hover {
      color: $primary !important;
      background: transparent !important;
    }
  }

  .verified {
    height: 25px !important;
    width: 25px !important;
    position: absolute;
    top: 10px;
    right: 15px;
    bottom: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.pMain {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: normal;

  @media (max-width: 992px) {
    flex-direction: row-reverse;
    gap: 20px;
  }

  .pLeft {
    width: 28%;
    background: #80808012;
    border-radius: 0px 0px 20px 20px;
    padding: 20px;
    height: 510px;
    overflow-y: scroll;

    @media (max-width: 992px) {
      width: 100%;
      margin-top: 20px;
    }
  }

  .userDetail {
    display: flex;
    align-items: center;

    figure {
      width: 70px;
      height: 70px;
      min-width: 70px;
      margin-right: 20px;
      background: #bebebe;
      border-radius: 50%;
      padding: 2px;

      img {
        object-fit: cover;
        border-radius: 50%;
      }

      @media (max-width: 1200px) {
        width: 50px;
        height: 50px;
        min-width: 50px;
        margin-right: 10px;
      }
    }

    .username {
      h3 {
        font-size: 18px;
        color: $c_black;
      }

      h4 {
        font-size: 16px;
        color: $c_black;
        padding-top: 4px;
        font-weight: 500;
      }
    }
  }
  .follow_dv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .flw_sc {
      border: 1px solid $primary;
      width: 48%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background: $primary;
        p {
          color: white;
        }
      }
      p {
        font-size: 16px;
        color: $c_black;
      }
      h6 {
        color: $secondary;
        padding-top: 4px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  ul {
    margin: 20px 0;

    .active_tab {
      h3 {
        color: $primary;
      }
    }

    li {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      cursor: pointer;

      figure {
        width: 50px;
        height: 50px;
        margin-right: 16px;

        @media (max-width: 1200px) {
          width: 40px;
          height: 40px;
        }
      }

      h3 {
        color: $c_black;
        font-size: 16px;

        &:hover {
          color: $primary;
        }
      }
    }
  }
  .followers_lst {
    margin: 20px 0 !important;
    .lstt {
      border-radius: 10px !important;
      padding: 20px !important;
      box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.07) !important;
      img {
        border-radius: 100% !important;
      }
    }
    .flw_lst {
      cursor: auto !important;
      border-radius: 10px !important;
      padding: 20px !important;
      box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.07) !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lst_lft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          border-radius: 100% !important;
        }
      }
      .lst_ryt {
        button {
          font-size: 15px !important;
        }
      }
    }
  }

  button {
    border: 1px solid var(--unnamed-color-7dbfdd);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #7dbfdd;
    border-radius: 100px;
    opacity: 1;

    // min-width: 100%;
    // margin-bottom: 30px;
    &:hover {
      background: $primary;
      color: $white;
      width: 100;
    }
  }
}
.ryt_dsply {
  display: block !important;
}

.pRyt {
  width: 69%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 13px 25px #00000012;
  border-radius: 20px;

  @media (max-width: 992px) {
    width: 100%;
    display: none;
  }

  .main {
    padding: 20px;

    h2 {
      font-size: 28px;
      color: $c_black;

      @media (max-width: 575px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        text-align: center;
      }
    }

    p {
      color: $c_black;
      font-size: 16px;
      padding-top: 8px;

      @media (max-width: 600px) {
        text-align: center;
      }
    }

    form {
      figure {
        width: 110px;
        height: 110px;
        margin-top: 30px;
        margin-bottom: 20px;
      }

      .form_control {
        margin-top: 7px;

        h3 {
          padding-bottom: 10px;

          font-size: 14px;
          font-weight: 300;
        }
      }

      .btn {
        width: 50%;

        @media (max-width: 600px) {
          width: 60%;
          margin: 0 auto;
        }
      }
    }
  }
}

.tabs {
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 16px #00000014;
  border-radius: 50px;
  padding: 3px;
  justify-content: space-between;

  h3 {
    width: 33%;
    text-align: center;
    padding: 11px;
    border-radius: 50px;
    color: $gray;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background: $primary;
      color: white;
    }
  }

  h3.active {
    background: $primary;
    color: white;
  }
}

ul.chat {
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 2px 16px #00000014;
    padding: 14px;
    border-radius: 13px;
    margin-bottom: 10px;
    background: white;
    padding-top: 20px;

    figure {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 10px;
      img {
        border-radius: 50%;
      }
    }

    h5 {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .contnt {
      h3 {
        font-size: 16px;
        padding-bottom: 5px;
      }

      p {
        font-size: 14px;
      }
    }

    &:hover {
      background: $primary;
      color: $white;

      .contnt {
        h3 {
          color: white;
        }
      }
    }
  }
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .userDetail {
    display: flex;
    align-items: center;

    figure {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 20px;
      cursor: pointer;
    }

    .username {
      h3 {
        font-size: 18px;
        color: $c_black;
      }

      h4 {
        font-size: 16px;
        color: $c_black;
        padding-top: 4px;
        font-weight: 500;
      }
    }
  }

  svg {
    color: $white;
    background: $secondary;
    border-radius: 50%;
    padding: 1px;
    font-size: 33px;
    cursor: pointer;
  }
}

.chat-div {
  max-height: 260px;
  min-height: 260px;
  overflow-y: scroll;
  margin: 10px 0;
  padding-right: 10px;
  .time {
    p {
      font-size: 16px;
      text-align: center;
      color: #888888 !important;
      font-weight: 300;
    }
  }

  .out-going {
    background: #7dbfdd;
    width: 40%;
    display: flex;
    margin-left: auto;
    padding: 20px;
    border-radius: 20px 20px 0px 20px;
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;

    @media (max-width: 575px) {
      width: 70%;
    }

    &::after {
      content: "";
      position: absolute;
      background: #7dbfdd;
      width: 20px;
      height: 20px;
      rotate: 45deg;
      right: 4.1px;
      bottom: -9px;
    }

    p {
      color: white !important;
      text-align: right;
      width: 100%;
      padding: 0 !important;
    }
  }

  .incoming {
    background: #518da8;
    width: 40%;
    display: flex;
    margin-right: auto;
    padding: 20px;
    border-radius: 20px 20px 20px 0px;
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;

    @media (max-width: 575px) {
      width: 70%;
    }

    &::after {
      content: "";
      position: absolute;
      background: #518da8;
      width: 20px;
      height: 20px;
      rotate: 45deg;
      left: 4.1px;
      bottom: -9px;
    }

    p {
      color: white !important;
      text-align: right;
      width: 100%;
      padding: 0 !important;
    }
  }
}
.ofr_prc {
  background: $primary;
  border-radius: 12px;
  margin-bottom: 40px;

  .ofr_prc_top {
    background: $secondary;
    border-radius: 12px;
    padding: 12px 16px;

    p {
      font-size: 16px;
      color: white;
      font-weight: 300;
    }

    h6 {
      font-size: 16px;
      color: white;
      font-weight: 400;
      padding: 6px 0;
    }

    h4 {
      font-size: 20px;
      color: white;
      font-weight: 400;
    }
  }

  .ofr_prc_btm {
    padding: 14px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 20px;
      color: white;
      font-weight: 400;
      cursor: pointer;
      &:hover {
        color: $secondary;
      }
    }
  }
}

.chat_foot {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  svg {
    color: $primary;
    cursor: pointer;
    margin-top: 9px;
  }

  > div {
    display: flex !important;
    justify-content: center !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 6px;
    margin-right: 10px;
  }

  textarea {
    border: none;
    border-radius: 0;
    max-height: 100px;
    overflow-y: auto !important;
    padding: 5px 15px;
    width: 100%;
  }
}

//myListing
.flx_dv_prfl {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hdr_btn {
    background: white;
    border: 1px solid #a3a3a3;
    border-radius: 40px;
    padding: 0 16px 0 8px;
    height: 48px;
    color: $c_black;

    figure {
      height: 34px;
      width: 34px;
      margin-right: 6px;
    }
  }

  .back {
    width: 90px;
    padding: 0;
    font-size: 14px;
  }
}

.categoty_fltrs {
  padding-top: 30px;
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;

  .fltrs_cat {
    border-radius: 30px;
    padding: 6px 24px;
    min-width: fit-content;
    background-color: #ebebeb;
    cursor: pointer;

    &:hover {
      background: $secondary;

      h4 {
        color: $white;
      }
    }

    h4 {
      font-size: 14px;
      font-weight: 300;
      color: #6a6a6a;
    }
  }

  .actv_cat {
    background: $secondary;

    h4 {
      color: $white;
    }
  }
}

.listing_sctn {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  padding-top: 30px;
}

//manage payments
.cards_sec {
  padding-top: 30px;

  .mn_crds {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    border-bottom: 0.5px solid #00000012;

    .crd_dtls {
      display: flex;
      align-items: center;

      figure {
        width: 50px;
        height: 30px;
        margin-right: 20px;
        border: 1px solid #d0d0d0;
        padding: 1px;
        border-radius: 2px;

        img {
          object-fit: contain;
        }
      }

      .dtl_mn {
        h4 {
          font-size: 16px;
          color: $c_black;
          font-weight: 400;
        }

        p {
          font-size: 16px;
          color: $gray;
          font-weight: 300;
          padding-top: 4px !important;
        }
      }
    }

    .delete_dv {
      height: 30px;
      width: 30px;

      img {
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
}

.add_crd {
  padding-top: 20px;
  display: flex;
  width: fit-content;
  cursor: pointer;

  figure {
    width: 50px;
    height: 30px;
    margin-right: 10px;
  }

  .add_details {
    figure {
      width: 50px;
      height: 30px;

      img {
        object-fit: contain;
      }
    }

    h4 {
      color: $secondary;
      font-size: 16px;
      font-weight: 400;
    }

    p {
      color: $gray !important;
      font-size: 14px !important;
      font-weight: 300 !important;
      padding-top: 4px !important;
    }

    .paymnt_cards {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 6px;

      figure {
        width: 40px;
        height: 20px;
        margin-right: 10px;

        img {
          object-fit: contain;
        }
      }
    }
  }
}

//payment modal

.payment_modl {
  padding-top: 30px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 16px;

  .cross {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;

    svg {
      cursor: pointer;
      color: $c_black;
    }
  }

  .dbl {
    display: flex;
    justify-content: space-between;
    width: 93%;
  }

  h2 {
    font-size: 24px;
    color: #000;
    font-weight: 500;
  }

  h5 {
    font-size: 15px;
    font-weight: 300;
    color: #6a6a6a;
    padding: 20px 0;

    span {
      color: $secondary;
    }
  }

  .custom_label2 {
    font-size: 15px;
    font-weight: 300;
    color: #6a6a6a;
  }

  .paymnt_modl_txtfld {
    // border-bottom: 1.5px solid #00000012 !important;

    input {
      padding: 0 !important;
      border-radius: 0 !important;
      color: black;
      font-weight: 600;
      height: 50px;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: 500;
      border-bottom: 1.5px solid #00000012 !important;

      &:focus {
        border: none;
        border-bottom: 1.5px solid $primary !important;
      }

      &:focus {
        border: none;
        border-bottom: 1.5px solid $primary !important;
      }
    }
    p {
      font-size: 14px !important;
      color: red !important;
      border-bottom: none !important;
      line-height: 18px !important;
    }

    figure {
      width: 50px;
      height: 45px;

      img {
        object-fit: contain;
      }
    }

    .cvv_img {
      height: 20px;
      width: 20px;
    }
  }
}

.custom_label2 {
  padding: 0;
}

.sv_crd {
  .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: $c_black !important;
  }
}

//manage addresses
.saved_addrrss {
  background: $secondary;

  p {
    color: white !important;
    font-weight: 300;
    font-size: 14px !important;
  }
}

.adrss_outr {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 575px) {
    padding: 15px;
  }

  .adrs_mn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: 0px 2px 16px #00000014;
    padding: 20px;
    border-radius: 20px;
    gap: 10px;

    .adrs_lft {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      span {
        padding: 0 !important;
      }

      svg {
        color: $secondary;
        margin-right: 10px;
      }

      .adrs_dtls {
        h4 {
          font-size: 16px;
          font-weight: 500;
        }

        p {
          font-size: 14px;
          font-weight: 300;
          color: #96979c;
          // width: 260px;
        }

        .edit_adrss {
          display: flex;
          justify-content: flex-start;
          padding-top: 10px;

          button {
            background-color: $primary;
            color: $white;
            padding: 6px 20px;
            border: none;
            border-radius: 30px;
            cursor: pointer;
            margin-right: 10px;
          }

          figure {
            height: 30px;
            width: 30px;

            img {
              object-fit: contain;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.new_addrss {
  figure {
    padding: 20px 0;
  }

  .adrss_mn {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      color: $secondary;
    }

    h4 {
      font-size: 14px;
      font-weight: 500;
      color: $c_black;
    }
  }

  h5 {
    font-size: 14px !important;
    color: $c_black;
    font-weight: 300 !important;
    padding-bottom: 20px !important;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: $c_black;
  }

  .save_as {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    gap: 5px;

    .saveas_btns {
      background: rgb(235, 235, 235);
      color: $gray;
      padding: 14px 20px;
      border-radius: 40px;
      width: 240px;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      text-align: center;

      &:hover {
        background: $primary;
        color: white;
      }
    }
  }

  .active_btn {
    background: $primary !important;
    color: white !important;
  }

  button {
    background: $primary;
    color: white;
    padding: 14px 20px;
    border-radius: 40px;
    max-width: 350px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
  }
}
.no_Chat {
  text-align: center;
  color: black;
  padding: 20px;
}
.no_followers_found {
  padding-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sv_adrss {
  display: flex;
  justify-content: flex-end;
  margin: 20px !important;
  width: 140px;
}
.addrss_sc {
  height: 300px;
  overflow-y: scroll;
  display: flex !important;
  flex-direction: row !important;
}
