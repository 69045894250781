@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-Thin.eot');
  src: url('/public/sitefonts/ProductSans-Thin.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-Thin.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-Thin.woff') format('woff'),
    url('/public/sitefonts/ProductSans-Thin.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-Thin.svg#ProductSans-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-ThinItalic.eot');
  src: url('/public/sitefonts/ProductSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-ThinItalic.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-ThinItalic.woff') format('woff'),
    url('/public/sitefonts/ProductSans-ThinItalic.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-ThinItalic.svg#ProductSans-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-Light.eot');
  src: url('/public/sitefonts/ProductSans-Light.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-Light.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-Light.woff') format('woff'),
    url('/public/sitefonts/ProductSans-Light.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-Light.svg#ProductSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-LightItalic.eot');
  src: url('/public/sitefonts/ProductSans-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-LightItalic.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-LightItalic.woff') format('woff'),
    url('/public/sitefonts/ProductSans-LightItalic.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-LightItalic.svg#ProductSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-Regular.eot');
  src: url('/public/sitefonts/ProductSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-Regular.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-Regular.woff') format('woff'),
    url('/public/sitefonts/ProductSans-Regular.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-Regular.svg#ProductSans-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-Italic.eot');
  src: url('/public/sitefonts/ProductSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-Italic.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-Italic.woff') format('woff'),
    url('/public/sitefonts/ProductSans-Italic.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-Italic.svg#ProductSans-Italic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-Medium.eot');
  src: url('/public/sitefonts/ProductSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-Medium.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-Medium.woff') format('woff'),
    url('/public/sitefonts/ProductSans-Medium.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-Medium.svg#ProductSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-MediumItalic.eot');
  src: url('/public/sitefonts/ProductSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-MediumItalic.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-MediumItalic.woff') format('woff'),
    url('/public/sitefonts/ProductSans-MediumItalic.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-MediumItalic.svg#ProductSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-Bold.eot');
  src: url('/public/sitefonts/ProductSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-Bold.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-Bold.woff') format('woff'),
    url('/public/sitefonts/ProductSans-Bold.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-Bold.svg#ProductSans-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-BoldItalic.eot');
  src: url('/public/sitefonts/ProductSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-BoldItalic.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-BoldItalic.woff') format('woff'),
    url('/public/sitefonts/ProductSans-BoldItalic.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-BoldItalic.svg#ProductSans-BoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-BlackItalic.eot');
  src: url('/public/sitefonts/ProductSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-BlackItalic.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-BlackItalic.woff') format('woff'),
    url('/public/sitefonts/ProductSans-BlackItalic.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-BlackItalic.svg#ProductSans-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('/public/sitefonts/ProductSans-Black.eot');
  src: url('/public/sitefonts/ProductSans-Black.eot?#iefix') format('embedded-opentype'),
    url('/public/sitefonts/ProductSans-Black.woff2') format('woff2'),
    url('/public/sitefonts/ProductSans-Black.woff') format('woff'),
    url('/public/sitefonts/ProductSans-Black.ttf') format('truetype'),
    url('/public/sitefonts/ProductSans-Black.svg#ProductSans-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Product Sans';
  background-color: #f5f5f5;}

  body :is(h1, h2, h3, h4, h5, h6, input, select, textarea, label, p, li, *) {
    font-family: 'Product Sans' !important;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f5f5f5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb {
  background: #7DBFDD;
}