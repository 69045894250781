@import "/src/assets/theme/variable.scss";
body {
  background: white !important;
}
.container {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
}

* {
  padding: 0;
  margin: 0;
  outline: none !important;
  box-sizing: border-box;
}

li {
  list-style: none;
}

img {
  width: 100%;
  height: 100%;
}

.wrapCls {
  padding-top: 60px;
  min-height: calc(100vh - 557px);
  padding-bottom: 60px;

  @media (max-width: 767px) {
    padding: 30px 0;
  }
}

.cht_revrs {
  flex-direction: unset !important;

  @media (max-width: 767px) {
    .pRyt {
      padding: 15px !important;
    }

    .pRyt .main {
      padding: 0px !important;
    }
  }
}

// Common
.anchor_link {
  color: $primary;
  // transition: $transition;
  cursor: pointer;

  &:hover {
    // color: $primary_hover;
  }
}

// Button
.MuiButton-root {
  &.btn {
    height: 50px;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.3px;
    box-shadow: none;
    text-transform: capitalize;
    gap: 5px;

    &:hover {
      box-shadow: none;
    }

    svg {
      font-size: 20px;
    }
  }

  &.btn_primary {
    color: $white;
    background-color: $primary;

    &:hover {
      // background-color: $primary_hover;
    }
  }

  &.btn_danger {
    color: $white;
    // background-color: $c_red;

    &:hover {
      // background-color: $c_red_hover;
    }
  }

  &.sm {
    height: 35px;
    padding: 5px 10px;
  }
}

// Forms
.custom_label[class*="MuiTypography"] {
  font-size: 14px;
  font-weight: 600;
  color: $c_black;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  line-height: 1.2;
}

.MuiFormControl-root,
.MuiTextField-root,
.react-tel-input {
  > .MuiInputBase {
    &-root {
      position: relative;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }

    &-hiddenLabel {
      > fieldset {
        display: none;
      }
    }
  }

  .react-tel-input .form-control {
    width: 100% !important;

    .search {
      input.search-box {
        width: 93% !important;
      }
    }
  }

  :is(input, select, input.form-control) {
    color: $c_black;
    font-weight: 500;
    height: 50px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 300;
    border-radius: 50px !important;
    border: 1px solid transparent;
    box-shadow: none !important;

    &:focus {
      border-color: $primary;
    }

    &::placeholder {
      color: $c_black;
      opacity: 0.6;
    }
  }

  :is(input, select) {
    padding: 5px 15px;
  }

  input:has(+ .eye_btn) {
    padding-right: 50px;
  }

  .eye_btn {
    position: absolute;
    height: 50px;
    max-height: 50px;
    width: 50px;
    margin: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 80%;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0 auto;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.MuiFormControlLabel-root {
  color: $c_black;
  line-height: 1.2;
  margin: 0 0 0 -3px !important;
  gap: 4px;

  .MuiCheckbox-root {
    padding: 0;
    // color: $c_form_border;
    position: relative;
    top: -1px;

    &.Mui-checked {
      color: $primary;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
  }
}

.form_btn {
  margin-top: 30px;
}

.search_bar {
  width: 100%;
  position: relative;

  .search_icon {
    position: absolute;
    width: auto;
    height: auto;
    margin: 0;
    max-height: initial;
    color: #999;
    left: 10px;
  }

  input {
    padding-left: 40px;
    background: $white;
  }
}

.react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: none !important;
}

.react-tel-input .selected-flag:focus:before {
  border-color: transparent !important;
  box-shadow: none !important;
}

.text_field.text_area {
  textarea {
    width: 100%;
    height: 134px;
    background-color: rgb(235, 235, 235) !important;
    border-radius: 20px !important;
    // margin-bottom: 15px;
    padding: 20px;
    border: 1px solid transparent !important;
    font-size: 14px;
    font-weight: 300;

    &:focus {
      border: 1px solid $primary !important;
    }
  }
}

.date-picker-container {
  margin-bottom: 15px;
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;

    .datepicker {
      width: 100%;
      background-color: rgb(235 235 235) !important;
      color: #1d1d1d;
      font-weight: 500;
      height: 50px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 300;
      border-radius: 50px !important;
      border: 1px solid transparent;
      box-shadow: none !important;
      padding: 0 15px;
    }
  }

  .calendar-icon {
    position: absolute;
    top: 14px;
    right: 15px;

    svg {
      color: $secondary;
      cursor: pointer;
    }
  }
}

.text_field p,
.err_msg {
  color: red !important;
  font-size: 14px !important;
  margin: 4px 0;
  font-weight: 400;
  padding: 0 !important;
}

.text_field {
  margin-bottom: 15px !important;

  input {
    background-color: rgb(235, 235, 235);
    border-radius: 50px;
    // margin-bottom: 15px;
  }
}

.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;

  figure {
    width: 60px;
    height: 60px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.logut_flx {
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
  margin: 30px 0 16px;

  button {
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}
.logut_flx1 {
  margin-top: 20px;
}

.image {
  width: 80px;
  height: 80px;

  border-radius: 50%;
  margin: 20px 0;
  padding: 4px;
  outline: 1px solid $secondary !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  figure {
    width: 100%;
    height: 100%;
    background: $lightGrey;
    overflow: hidden;
    margin: auto;

    border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  svg {
    position: absolute;
    bottom: -13px;
    background: $secondary;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    font-size: 21px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
button.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
.text_field {
  .Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: $c_black !important;
    cursor: not-allowed;
  }
}

.srch_inpt > div {
  position: relative !important;
  padding: 0 !important;
  background: white !important;
  border-radius: 50px !important;
  padding-right: 5px !important;
}

.srch_inpt {
  input {
    &:focus {
      border-color: white !important;
    }
  }

  svg {
    cursor: pointer;
  }
}

.noData {
  margin: auto;

  figure {
    width: 100px;
    height: 100px;
    margin: auto;

    img {
      filter: brightness(1.7);
    }
  }

  h3 {
    font-size: 16px;
    color: $primary;
    padding-top: 5px;
    text-align: center;
  }
}

.see_heading {
  h3 {
    font-size: 33px;
    margin-bottom: 30px;
    font-weight: 500;
    color: $secondary;

    @media (max-width: 600px) {
      font-size: 30px;
    }

    @media (max-width: 420px) {
      font-size: 20px;
    }
  }
}

.top_mt {
  margin-top: 200px;
}

// .boosterValidity{
//   background: $secondary;
//   color: white;
//   padding: 7px;
//   border-radius: 5px;
//   margin-top: 20px;
// }
.mn_brdcrmbs {
  background: rgba(128, 128, 128, 0.0705882353);
  .brdcrmb {
    &:hover {
      color: $primary;
    }
  }
}
.no_brdcrmbs {
  display: none;
}
.img_style {
  object-fit: cover !important;
}

.img_style2 {
  object-fit: contain !important;
}
.paymnt_nodata {
  height: 350px;
}
