.vw_prfl {
  display: flex;
  justify-content: space-between;

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.vw_lft {
  width: 27%;
  background-color: #fafafa;
  padding: 50px 20px 20px 20px;
  margin-top: 3px;

  @media (max-width: 1200px) {
    width: 30%;
  }

  @media (max-width: 992px) {
    width: 100%;
    padding: 20px;
  }

  @media (max-width: 767px) {
    padding: 20px 10px;
  }
}

.vw_ryt {
  width: 73%;
  padding: 50px 30px 40px 30px;

  @media (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 992px) {
    width: 100%;
    padding: 20px 0px;
  }
}

.vw_lft figure {
  width: 260px;
  height: 260px;
  border: 10px solid #820000;
  border-radius: 50%;
  overflow: hidden;
  @media (max-width: 1200px) {
    width: 200px;
    height: 200px;
  }

  @media (max-width: 992px) {
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    width: 130px;
    height: 130px;
  }
}

.vw_lft figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.vw_lft h3 {
  color: #820000;
  font-size: 30px;
  margin: 20px 0px;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 25px;
  }

  @media (max-width: 992px) {
    text-align: center;
  }
}

.vw_ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;

  @media (max-width: 992px) {
    flex-direction: row;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
}

.vw_ul li {
  background-color: #fff;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 2px 26px #0000001a;
  border-radius: 10px;

  @media (max-width: 992px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 30%;
    padding: 10px;
  }
}

.vw_ul li p {
  font-size: 18px;
  color: #5d5d5d;

  @media (max-width: 600px) {
    font-size: 15px;
  }
}

.vw_ul li p span {
  font-size: 28px;
  color: #820000;
  font-weight: 600;
  display: block;

  @media (max-width: 767px) {
    font-size: 22px;
    margin-top: 10px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }
}

.vw_ryt .vw_prfl_hdng {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vw_ryt h2 {
  font-size: 30px;

  @media (max-width: 600px) {
    font-size: 25px;
  }
}

.vw_ryt .vw_prfl_hdng svg {
  background-color: #820000;
  color: #fff;
  height: 39px;
  width: 39px;
  border-radius: 50%;
  padding: 4px;

  @media (max-width: 600px) {
    height: 30px;
    width: 30px;
  }
}

.vw_ryt .categoty_fltrs {
  flex-wrap: nowrap;
  height: 88px;
  overflow: scroll;
  overflow-y: hidden;
}

.vw_ryt .prdcts_sc {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;

  @media (max-width: 1200px) {
    gap: 12px;
  }
}

.vw_lft button {
  width: 200px !important;
  margin: 0 auto;

  display: flex;
}

.vw_ryt .prdcts_sc .prdct_crd {
  width: 23%;

  @media (max-width: 1200px) {
    width: 31.9%;
  }

  @media (max-width: 992px) {
    width: 32%;
  }

  @media (max-width: 767px) {
    width: 48%;
  }

  @media (max-width: 600px) {
    width: 47%;
  }
}
