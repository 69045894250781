@import "../../assets/theme/variable.scss";

.prdct_dtl_hdng {
  font-size: 30px;
  font-weight: 500;
  color: $secondary;
  padding-bottom: 20px;

  @media (max-width: 576px) {
    font-size: 24px;
  }
}

.detail_main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .dLeft {
    width: 58%;

    @media (max-width: 992px) {
      width: 100%;
      margin-bottom: 20px;
    }

    .prdct_img_sc {
      border-radius: 20px !important;
      overflow: hidden;
      width: 100%;
      height: 376px;

      img {
        object-fit: cover;
      }
    }

    .p_Imgs {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 15px;

      @media (max-width: 575px) {
        display: none;
      }

      figure {
        border-radius: 10px !important;
        overflow: hidden;
        height: 151px;
        width: 24%;
        cursor: pointer;
      }
    }
  }

  .dRyt {
    width: 39%;

    @media (max-width: 992px) {
      width: 100%;
    }

    .rightCard {
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 16px #00000014;
      border-radius: 10px;
      opacity: 1;
      padding: 20px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 575px) {
        display: block;

        .auto_left {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
        }
      }

      .auto_ryt {
        h3 {
          font-size: 16px;
          color: #5d5d5d;
        }

        h2 {
          font-size: 26px;
          padding-top: 13px;

          @media (max-width: 575px) {
            font-size: 20px;
          }
        }

        .p2 {
          font-size: 16px;
          color: $gray;
          padding: 8px 0;
        }
      }

      .auto_left {
        .fig {
          display: flex;
          justify-content: flex-end;

          figure {
            margin-left: 13px;
            width: 36px;
            height: 36px;
            cursor: pointer;
            border: 1px solid $secondary;
            border-radius: 50%;
            padding: 5px;
          }
        }

        h2 {
          font-size: 26px;
          color: $secondary;
          padding-top: 10px;

          @media (max-width: 600px) {
            font-size: 20px;
          }
        }
      }
    }

    .rightCard2 {
      margin: 20px 0;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 16px #00000014;
      border-radius: 10px;
      opacity: 1;
      padding: 20px;

      .card_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .cd_lft {
          display: flex;
          align-items: center;

          figure {
            width: 85px;
            min-width: 85px;
            height: 85px;
            background: #80808052;
            padding: 4px;
            border-radius: 100%;

            @media (max-width: 1200px) {
              width: 65px;
              min-width: 65px;
              height: 65px;
            }

            img {
              object-fit: cover;
              border-radius: 100%;
            }
          }

          .username {
            margin-left: 17px;

            h3 {
              font-size: 18px;
              color: $c_black;
            }

            h4 {
              font-size: 15px;
              padding-top: 2px;
              color: $c_black;
              font-weight: 400;
            }
          }
        }

        .cd_ryt {
          @media (max-width: 575px) {
            width: 100%;
            text-align: center;
            margin-top: 20px;
          }

          p {
            font-size: 14px;
            cursor: pointer;

            &:hover {
              border-bottom: 1px solid $c_black;
              color: $primary;
            }
          }
        }
      }

      .card_btm {
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        button {
          figure {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }

          .offr_img {
            img {
              filter: invert(0.9);
            }
          }

          width: 48%;
          border: 1px solid $primary;
          border-radius: 30px;
          font-size: 14px;
          text-transform: capitalize;
          color: $c_black;

          &:hover {
            background: $primary;
            color: $white;

            figure {
              img {
                filter: brightness(0) invert(1);
              }
            }
          }

          @media (max-width: 480px) {
            width: 100%;
            margin: 10px 0;
          }
        }
      }
    }
  }
}

.description {
  padding-top: 30px;

  h2 {
    font-size: 28px;
    color: $secondary;
  }

  p {
    font-size: 18px;
    color: $gray;
    margin: 20px 0;
  }
}

//category page
.choose {
  h2 {
    font-size: 30px;
    padding-bottom: 40px;
    text-align: center;
    color: $c_black;
  }

  .options {
    width: 50%;
    margin: auto;

    @media (max-width: 992px) {
      width: 80%;
    }

    @media (max-width: 575px) {
      width: 100%;
    }

    ul {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 16px #0000001a;
      border-radius: 20px;
      padding: 20px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #8080804d;
        padding: 15px 0;
        cursor: pointer;

        &:last-child {
          border-bottom: 1px solid $white;
          padding-bottom: 0;
        }

        &:first-child {
          padding-top: 0;
        }

        &:hover {
          background: rgb(128 128 128 / 16%);
        }

        .cate_name {
          display: flex;
          align-items: center;
          justify-content: space-between;

          figure {
            width: 70px;
            height: 70px;
            margin-right: 20px;

            @media (max-width: 575px) {
              width: 50px;
              height: 50px;
              min-width: 50px;
              margin-right: 10px;
            }
          }

          h3 {
            font-size: 20px;
            color: $c_black;

            @media (max-width: 575px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.more_details {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
}

.more_Info {
  opacity: 1;
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.078431372);
  border-radius: 10px;
  margin-bottom: 20px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
}

.make_an_offer {
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 16px;
  .cross {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;
    svg {
      cursor: pointer;
      color: $c_black;
    }
  }
  button {
    color: #fff;
    background: #7dbfdd;
    border: 1px solid #7dbfdd;
    border-radius: 30px;
    width: 100%;

    &:hover {
      color: $c_black;
    }
  }
  .offers_showing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .ofrs_dv {
      padding: 10px;
      border: 1px solid $primary;
      border-radius: 30px;
      color: $c_black;
      text-align: center;
      font-size: 12px;
      width: 30%;
      cursor: pointer;
      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }
  .make_ofr {
    width: 50%;
    margin: 0 auto;
    .ofr_inpt {
      margin-bottom: 20px;
      border: none;
      input {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        border-bottom: 1px solid black !important;
        border-radius: 0 !important;
        &:focus {
          border-color: transparent !important;
          border-bottom: 1px solid $primary !important;
        }
        &::placeholder {
          text-align: center !important;
          color: black !important;
        }
      }
    }
  }

  h2 {
    font-size: 28px;
    color: $secondary;
  }
  p {
    font-size: 16px;
    color: #908fa1;
    padding-top: 7px;
    padding-bottom: 20px;
    span {
      color: $secondary;
    }
  }
}
.active_price {
  background: $primary;
  color: white !important;
}
